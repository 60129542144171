import { Suspense } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-date-picker/dist/DatePicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
import Router from "./routes";
import { distributeMenuByRole } from "./helpers/AccessControlHandlers";
import { ROLE_ENUM } from "./config/config";
const App = () => {
  let initialize = false;

  if (!initialize) {
    const adminMenuData = [
      {
        router: "",
        name: "Dashboard",
        iconCssClass: "fa fa-dashboard",
        subModules: [],
      },
      {
        router: "requests",
        name: "Requests",
        iconCssClass: "fa-solid fa-envelope-open-text",
        subModules: [],
      },
      {
        router: "quotations",
        name: "Quotations",
        iconCssClass: "fa-solid fa-file-import",
        subModules: [],
      },
      // {
      //   router: "indents",
      //   name: "Indents",
      //   iconCssClass: "fa-solid fa-file-signature",
      //   subModules: [],
      // },
      {
        router: "customer",
        name: "Customer",
        iconCssClass: "fa-solid fa-address-book",
        subModules: [],
      },
      {
        router: "supplier",
        name: "Supplier",
        iconCssClass: "fa-solid fa-store",
        subModules: [],
      },
      {
        router: "product",
        name: "Product",
        iconCssClass: "fa-solid fa-box",
        subModules: [],
      },
      {
        router: "setup",
        name: "Setup",
        iconCssClass: "fa fa-cog",
        subModules: [
          {
            router: "company",
            name: "Company",
            subModules: [],
            iconCssClass: "fa-solid fa-building",
          },
          {
            router: "employee",
            name: "Employee",
            subModules: [],
            iconCssClass: "fa fa-users",
          },
          {
            router: "country",
            name: "Country",
            subModules: [],
            iconCssClass: "fa-solid fa-globe",
          },
          {
            router: "uom",
            name: "UOM",
            subModules: [],
            iconCssClass: "fa-solid fa-scale-balanced",
          },
          {
            router: "shipmentterms",
            name: "Shipment Terms",
            subModules: [],
            iconCssClass: "fa fa-ship",
          },
          {
            router: "paymentterms",
            name: "Payment Terms",
            subModules: [],
            iconCssClass: "fa fa-dollar-sign",
          },
        ],
      },
    ];

    const sxMenuData = [
      {
        router: "",
        name: "Dashboard",
        iconCssClass: "fa fa-dashboard",
        subModules: [],
      },
      {
        router: "quotations",
        name: "Quotations",
        iconCssClass: "fa-solid fa-file-import",
        subModules: [],
      },
      {
        router: "supplier",
        name: "Supplier",
        iconCssClass: "fa-solid fa-store",
        subModules: [],
      },
      {
        router: "product",
        name: "Product",
        iconCssClass: "fa-solid fa-box",
        subModules: [],
      },
    ];

    const cxMenuData = [
      {
        router: "",
        name: "Dashboard",
        iconCssClass: "fa fa-dashboard",
        subModules: [],
      },
      {
        router: "requests",
        name: "Requests",
        iconCssClass: "fa-solid fa-envelope-open-text",
        subModules: [],
      },
      {
        router: "customer",
        name: "Customer",
        iconCssClass: "fa-solid fa-address-book",
        subModules: [],
      },
      {
        router: "product",
        name: "Product",
        iconCssClass: "fa-solid fa-box",
        subModules: [],
      },
    ];

    distributeMenuByRole(adminMenuData, ROLE_ENUM.ADMIN);
    distributeMenuByRole(sxMenuData, ROLE_ENUM.SX);
    distributeMenuByRole(cxMenuData, ROLE_ENUM.CX);

    initialize = true;
  }

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
