// ExamplePortal.js

import ReactDOM from 'react-dom';
import { toast } from "react-toastify";

const ModalPortal = ({ children }) => {
  const portalRoot = document.getElementById("loader-portal");

  if (!portalRoot) {
    throw toast.error(
      new Error(
        'The portal root element is not found in the DOM. Create a <div id="portal-root"></div> element in your index.html.'
      )?.message
    );
    
  }

  return ReactDOM.createPortal(children, portalRoot);
};

export default ModalPortal;
