import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialState = {
  token: "",
  refreshToken: "",
  isLogin: false,
  user: null,
};

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    authReducer: (state, action) => {
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLogin = true;
      state.user = jwt_decode(action.payload.accessToken);
    },
    refreshToken: (state, action) => {
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    isSignout: (state) => {
      state.token = "";
      state.refreshToken = "";
      state.isLogin = false;
      state.user = null;
    },
  },
});

export const { authReducer, refreshToken, isSignout } = authSlice.actions;
export default authSlice.reducer;
