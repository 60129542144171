export const apiUrl = () => {
  let url;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  switch (environment) {
    case "production":
      // url = "http://23.227.206.234:3012"; //live
      url = "https://indent.qplnk.com/backend/api/v1";
      break;
    case "development":
      url = "https://indent.qplnk.com/backend/api/v1";
      // url = "http://192.168.1.11:3301/api/v1";
      // url = "http://23.227.206.234:5012";
      // url = "http://127.0.0.1:3301/api/v1/";
      break;
    case "staging":
      url = "http://192.168.1.11:2022";
      break;
    case "stagingLive":
      url = "http://23.227.206.234:5012";
      break;
    default:
      url = "https://localhost:7069";
  }
  return url;
};

// <<<<<<< Updated upstream
// export const apiUrl = "http://103.24.99.223:2031/";
// export const apiUrl = "https://localhost:7069";
// =======
// export const apiUrl = "http://localhost:4000";
// const apiUrl = "https://4171-110-38-247-71.ngrok-free.app";
// export const apiUrl = "http://192.168.1.11:2022";
// export const apiUrl = "https://localhost:7069";
// >>>>>>> Stashed changes

export const BaseUrl = (url) => `${apiUrl()}${url}`;
export const CompanyID = 1;
export const Version = "v1.0.0";

export const ROLE_ENUM = {
  ADMIN: "Admin",
  CX: "Customer Agent",
  SX: "Supplier Agent",
};

export const REQUEST_STATUS_ENUM = {
  NEW: "New",
  REVIEWED: "Reviewed",
  PARTIAL_RESPONSE: "Partial Response",
  COMPLETE_RESPONSE: "Complete Response",
  PARTIAL_NEGOTIATION: "Partial Negotiation",
  COMPLETE_NEGOTIATION: "Complete Negotiation",
  PARTIAL_ACCEPTED: "Partial Accepted",
  COMPLETE_ACCEPTED: "Complete Accepted",
  COMPLETE_ISSUED: "Complete Issued",
  PARTIAL_ISSUED: "Partial Issued",
  CANCELLED: "Cancelled",
};

export const QUOTATION_STATUS_ENUM = {
  NEW: "New",
  REVIEWED: "Reviewed",
  PARTIAL_RESPONSE: "Partial Response",
  COMPLETE_RESPONSE: "Complete Response",
  PARTIAL_NEGOTIATION: "Partial Negotiation",
  COMPLETE_NEGOTIATION: "Complete Negotiation",
  PARTIAL_ACCEPTED: "Partial Accepted",
  COMPLETE_ACCEPTED: "Complete Accepted",
  COMPLETE_ISSUED: "Complete Issued",
  PARTIAL_ISSUED: "Partial Issued",
  CANCELLED: "Cancelled",
};

export const REQUEST_ITEM_STATUS_ENUM = {
  NEW: "New",
  PROPOSED: "Proposed",
  COUNTERED: "Countered",
  ACCEPTED: "Accepted",
  CANCELLED: "Cancelled",
};

export const NEGOTIATION_STATUS_ENUM = {
  PROPOSED: "Proposed", //req=>show
  COUNTERED: "Countered", //quo => show
  ACCEPTED: "Accepted",
  SUPPLIER_ACCEPTED: "Supplier Accepted", //req => show
  CANCELLED: "Cancelled",
};

export const INDENT_STATUS_ENUM = {
  NEW: "New",
  SUPPLIER_ACCEPTED: "Supplier Accepted",
  CUSTOMER_ACCEPTED: "Customer Accepted",
  PROCESS: "In Process",
  COMMISSION_RECEIVED: "Commission Received",
  CANCELLED: "Cancelled",
};
