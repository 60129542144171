import { lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { doesPathExist } from "./helpers/AccessControlHandlers";
import ErrorPage from "./pages/ErrorPage";
const Layout = lazy(() => import("./Layouts"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const CustomerPage = lazy(() => import("./pages/Customer"));
const SupplierPage = lazy(() => import("./pages/Supplier"));
const ProductPage = lazy(() => import("./pages/Product"));
const CompanyPage = lazy(() => import("./pages/Company"));
const EmployeePage = lazy(() => import("./pages/Employee"));
const CountryPage = lazy(() => import("./pages/Country"));
const UOMPage = lazy(() => import("./pages/UOM"));
const STPage = lazy(() => import("./pages/ShipmentTerms"));
const PTPage = lazy(() => import("./pages/PaymentTerms"));
const RequestsPage = lazy(() => import("./pages/Requests"));
const RequestDetails = lazy(() => import("./pages/RequestDetails"));
const QuotationsPage = lazy(() => import("./pages/Quotations"));
const QuotationDetails = lazy(() => import("./pages/QuotationDetails"));
const IndentsPage = lazy(() => import("./pages/Indents"));
const IndentDetails = lazy(() => import("./pages/IndentDetails"));

export default function Router() {
  const location = useLocation();
  const val = location.pathname.split("/")[1];

  const token = useSelector((rootState) => rootState.authReducer.token);
  const user = useSelector((rootState) => rootState.authReducer.user);

  const authRoutes = useRoutes([
    {
      path: "",
      element: <Login />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  const userRoutes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Dashboard />, index: true },
        { path: "customer", element: <CustomerPage /> },
        { path: "supplier", element: <SupplierPage /> },
        { path: "product", element: <ProductPage /> },
        { path: "company", element: <CompanyPage /> },
        { path: "employee", element: <EmployeePage /> },
        { path: "country", element: <CountryPage /> },
        { path: "uom", element: <UOMPage /> },
        { path: "shipmentterms", element: <STPage /> },
        { path: "paymentterms", element: <PTPage /> },
        { path: "requests", element: <RequestsPage /> },
        { path: "requests/:id", element: <RequestDetails /> },
        { path: "quotations", element: <QuotationsPage /> },
        { path: "quotations/:id", element: <QuotationDetails /> },
        { path: "indents", element: <IndentsPage /> },
        { path: "indents/:id", element: <IndentDetails /> },
        { path: "*", element: <Navigate to="/" /> },
      ],
    },
  ]);

  const notFoundRoutes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);

  if (token) {
    if (doesPathExist(user, val)) {
      return userRoutes;
    } else {
      return notFoundRoutes;
    }
  } else {
    return authRoutes;
  }
}
