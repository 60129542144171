import { ROLE_ENUM } from "../config/config";

const superAdminMenu = [];
const adminMenu = [];
const cxMenu = [];
const sxMenu = [];

const createMenuObject = (
  router = "",
  name = "",
  subModules = [],
  iconCssClass = ""
) => ({
  router,
  name,
  subModules,
  iconCssClass,
});

const createMenuList = (menuData) => {
  return menuData.map((item) => {
    return createMenuObject(
      item.router,
      item.name,
      createMenuList(item.subModules), // Recursively create subModules
      item.iconCssClass
    );
  });
};

const distributeMenuByRole = (menuData, role) => {
  const menu = createMenuList(menuData);

  if (role === ROLE_ENUM.ADMIN) {
    adminMenu.push(...menu);
  } else if (role === ROLE_ENUM.CX) {
    cxMenu.push(...menu);
  } else if (role === ROLE_ENUM.SX) {
    sxMenu.push(...menu);
  } else if (role === ROLE_ENUM.SUPER_ADMIN) {
    superAdminMenu.push(...menu);
  }

  // console.log({superAdminMenu, adminMenu, cxMenu, sxMenu});
};

const checkAccess = (user, val) => {
  return true;
};

const doesPathExist = (user, val) => {
  const menu = getMenu(user);

  return checkPath(menu, val);
};

const checkPath = (menu, val) => {
  const value = val.split("/");

  for (const item of menu) {
    if (item.subModules.length > 0) {
      return checkPath(item.subModules, val);
    }

    if (value[0].toLowerCase() == item.router) {
      return true;
    }
  }

  return false;
};

const getMenu = (user) => {
  if (user.roles.includes(ROLE_ENUM.ADMIN)) {
    return adminMenu;
  } else if (user.roles.includes(ROLE_ENUM.CX)) {
    return cxMenu;
  } else if (user.roles.includes(ROLE_ENUM.SX)) {
    return sxMenu;
  } else if (user.roles.includes(ROLE_ENUM.SUPER_ADMIN)) {
    return superAdminMenu;
  } else {
    return null;
  }
};

const includePackageToggler = (data) => {
  var updatedData = [];
  if (data.subModules?.length > 0 && Array.isArray(data.subModules)) {
    for (const item of data.subModules) {
      includePackageToggler(item.subModules);
    }
  } else {
    updatedData = !data.selected;
  }
  return updatedData;
};

export {
  distributeMenuByRole,
  checkAccess,
  doesPathExist,
  includePackageToggler,
  getMenu,
};
