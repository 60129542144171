import React from "react";
import classes from "./Loader.module.css";
import { logo } from "../../constant/imagePath";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";
import LoaderPortal from "../../portals/LoaderPortal";
const Loader = () => {
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    // Set a timeout to hide the loader after 5 seconds
    const timeout = setInterval(() => {
      setShowLoader(false);
    }, 9000);

    // Clear the timeout when the component is unmounted
    return () => clearInterval(timeout);
  }, []);

  return (
    <>
      {showLoader ? (
        <LoaderPortal>
          <div
            className={classes["loader-wrapper"]}
            style={{ display: showLoader ? "flex" : "none" }}
          >
            <div className={classes["loader-box"]}>
              <img src={logo} alt="brand-logo" />
            </div>
          </div>
        </LoaderPortal>
      ) : null}
    </>
  );

  // <div className={classes["loader-wrapper"]}>
  //   <div className={classes["loader-wrapper__container"]}>
  //     <Spinner animation="grow" />
  //     <Spinner animation="grow" />
  //     <Spinner animation="grow" />
  //   </div>
  // </div>
};

export default Loader;
